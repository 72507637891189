import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';


import { APP_ROUTING } from './app.routers';

import { InfoService } from './services/info.service';

import { AppComponent }      from './app.component';
import { HeaderComponent}    from './components/header/header.components';
import { BodyComponent }     from './components/body/body.component';
import { ViewComponent }     from './components/view/view.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { AsideComponent }    from './components/aside/aside.component';
import { FooterComponent }   from './components/footer/footer.component';
import { AnswerComponent }   from './components/answer/answer.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    ViewComponent,
    BuscadorComponent,
    AsideComponent,
    FooterComponent,
    AnswerComponent
  ],
  imports: [
    APP_ROUTING,
    BrowserModule,
    HttpModule,
  ],
  providers: [
    InfoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
