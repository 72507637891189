import { RouterModule, Routes } from '@angular/router';

import { BodyComponent } from './components/body/body.component';
import { ViewComponent } from './components/view/view.component';
import { AnswerComponent } from './components/answer/answer.component';
import { HeaderComponent } from './components/header/header.components';

const APP_ROUTES : Routes = [
   { path: 'home',  component: BodyComponent },
   { path: 'home/:hash', component: BodyComponent },
   { path: 'home/:hash/link/:link', component: BodyComponent },
   { path: 'buscador/:termino', component: AnswerComponent },
   { path: 'link/:id', component: ViewComponent },
   { path: '**', pathMatch: 'full', redirectTo: 'home'}
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);


