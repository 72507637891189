import { Component } from '@angular/core';
import { Http } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  info:any[] = [];
  hash:string;
  Vinculos:any;
  constructor(){
  }
  title = 'centerKnow';
}
