import { Component } from '@angular/core';
import { Http } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoService } from '../../services/info.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css'],
  providers: [InfoService]
})
export class BodyComponent {
    info:any;
    hash:any;
    secciones:any;
    Vinculos:any = {};
    constructor(private http:Http, private rout:ActivatedRoute, private router:Router, private _info:InfoService) {
      if(!localStorage.getItem("data")){
        this.getInfo().then((resp)=>{
          this.info = resp;
          this.secciones = this.getInfoBody(this.info); 
          this._info.getInfo(this.info);
          this.rout.params.subscribe(params => {
            if(params.link){
              this.router.navigate(["/link", params.link]);
            }
          });
        });
      }else{
        this.info = JSON.parse(localStorage.getItem("data"));
        this.secciones = this.getInfoBody(this.info); 
        this._info.getInfo(this.info);
      }
    }
           
    getHash(){
      return new Promise((resul)=>{this.rout.params
        .subscribe(params => {
          var hash = "";
          if(params.hash){
            hash = params.hash;
            localStorage.setItem('hash',hash);
          }else{
            if(localStorage.getItem('hash')){
              hash = localStorage.getItem("hash");
            }
          }
          resul(hash);
        });
      });
    }
    getInfo(){
      return new Promise((resul)=>{
          this.getHash().then((resultado:string)=>{
            let formData = new FormData();
            formData.append("data",resultado);
            return this.http.post("https://ayuda.colpegasus.com/php/cliente.php",formData)
             .subscribe((data:any)=>{
                   var datos =  JSON.parse(data._body);
                   this.Vinculos = datos.resp;
                   localStorage.setItem("perfil",datos.perfil);
                   localStorage.setItem("data", JSON.stringify(this.Vinculos));
                   resul(this.Vinculos);
             });
          });
      });
    }    
    getVinculos(){
        return this.Vinculos;
    }
    getInfoBody(vinculos:any){
      this.Vinculos = vinculos;
      var seccion = new Array();
      var seccionF = new Array();
      var i = 0;
      var j = 1;
      vinculos.forEach((element, index) => {
        if (!seccion[element.id_seccion]) { 
            seccion[element.id_seccion] = {};
        }
        seccion[element.id_seccion]["destacada"] = element.seccion_destacada;
        seccion[element.id_seccion]["id_seccion"] = element.id_seccion;          
        seccion[element.id_seccion]["seccion"] = element.seccion;
      });
      seccion.forEach((element) => {
        if (!seccionF[i]) { 
              seccionF[i] = {};
        }
        if(element.destacada == "0"){
            element.number = j;
            j++; 
        }
        seccionF[i] = element;
        i++;
      });
      return seccionF;
    }
}


