import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Http } from '@angular/http';
import { InfoService } from '../../services/info.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
  providers:[InfoService]
})
export class ViewComponent implements OnInit {
  numberAct:number;
  vinculos:any;
  vinculos2:any;
  vinculo:any = {};
  vinculo2:any ={};
  vinculo3:any ={};
  thim1:boolean= false;
  thim2:boolean= false;
  colum:string;
  send:boolean;
  constructor( private activeRouter: ActivatedRoute, 
               private http:Http, private _info:InfoService, private router:Router
              ) {
    this.vinculos = JSON.parse(localStorage.getItem('data'));
    console.log('--> ', this.vinculos);
    this.activeRouter.params.subscribe(params => {
      this.thim1 = false;
      this.thim2 = false;   
      var a,b = 0;
      let num:number = Number(params.id), i;
      this.numberAct = this.vinculos.length;
      this.vinculos.forEach((element, index) => {
        if(element.id == num){
          this.vinculo = element;
          i = index;
        }  
      });
      if(!this.vinculo.contenido){
        let formData = new FormData();
        formData.append("idContenido",this.vinculo.id);
        this.http.post("https://ayuda.colpegasus.com/php/cliente.php",formData)
          .subscribe((data:any)=>{
                this.vinculo.contenido = this._info.converHtml(data._body);   
                this.vinculos[i].contenido = this.vinculo.contenido;
                localStorage.setItem("data",JSON.stringify(this.vinculos));
          });
      }
      if(i === 0 ){
        a = this.numberAct-1;
        b = i+1;      
      }else if(i < this.numberAct-1){
        a = i-1;
        b = i+1;
      }else{
        a = i-1;
        b = 1;
      }
      this.vinculo2 = this.vinculos[a];
      this.vinculo3 = this.vinculos[b];
      this.vinculos[i].numero_visitas = Number(this.vinculo.numero_visitas) +1;
      this.colum = "numero_visitas";
      this.sendVist(this.vinculo.id, this.colum);
      this.send = true;
      localStorage.setItem("data",JSON.stringify(this.vinculos)); 
    });
  }
  sendVotePos(){
    if(this.send){
          this.colum = "voto_positivo";
          this.sendVist(this.vinculo.id, this.colum);
          this.send = !this.send;
          this.thim1 = !this.thim1;
    }
  }
  sendVoteNeg($event){
    if(this.send){
        this.colum = "voto_negativo";
        this.sendVist(this.vinculo.id, this.colum);
        this.thim2 = !this.thim2;
        this.send = !this.send;
    }
  }
  sendVist(id:string, colum:string){
    let formData = new FormData();
    formData.append(colum,id);
    this.http.post("http://ayuda.colpegasus.info/php/cliente.php", formData)
    .subscribe((data)=>{
                     return true;
                },(error)=>{
                 console.log("error::", error);
     });
  } 
  llegada(event){
    let href = event.target.getAttribute('href'); 
    if( href.indexOf("link") == 1 || href.indexOf("home") == 1){
        this.router.navigate([href]);
        return false;
    }
  }
  ngOnInit() {}
}
