import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfoService } from '../../services/info.service';
import { BuscadorComponent } from '../buscador/buscador.component';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.css'],
  providers: [BuscadorComponent]
})
export class AnswerComponent implements OnInit {
  answer:any; 
  vinculos:any;
  termino:string; 
  numAns:number;
  constructor( private activatedRoute:ActivatedRoute, private _info:InfoService, private _buscador:BuscadorComponent) { 
    this.vinculos = JSON.parse(localStorage.getItem("data")); 
   }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params=>{
      this.termino = params['termino'].replace("-"," ");
      this.answer = this.search(params['termino']);
      console.log(this.answer);
    })
  }
  search(termino:string){
    this.numAns = 0;
    var search = [];
    var dict = {"á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u"};
    termino = termino.replace(/[^\w ]/g, function(char) {
      return dict[char] || char;
    });
    this.vinculos.forEach((element, index) => {
      let nombre = element.titulo.toLowerCase();
      nombre = nombre.replace(/[^\w ]/g, function(char) {
        return dict[char] || char;
      });
      nombre = nombre.replace(" ","-");
      if(nombre.indexOf(termino) >= 0){
        search[this.numAns] = {id:element.id, index:index};
        this.numAns++;
      }
    });
    return search;
  }

}
