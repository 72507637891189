import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InfoService } from '../../services/info.service';


@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css'],
  providers: [InfoService]
})
export class BuscadorComponent  {
  info :any = {
      perfil: '',
      videos: 0,
      text2: 'Vinculos de ayuda para los todos los perfiles' 
  };
  addTaskValue:string = "";
  typingTimer:any = 0;
  vinculos:any;
  text:string = "Escriba una frase o palabra clave de lo que busca";
 constructor(private _info:InfoService, private router:Router) { 
    setTimeout(() => {
            this._info.sendVinculos().then(respu=>{
                this.vinculos = respu;
                this.info.videos = this.vinculos.length;
            });     
            this._info.sendPerfil().then(respu=>{
                this.info.perfil = respu;
                if(this.info.perfil){
                    this.info.text2 = "Vinculos de ayuda para el perfil"
                }
            });
    },600);   
     if(screen.width>600){
        if(this.text != "Escriba una frase o palabra clave de lo que busca"){
            this.text = "Escriba una frase o palabra clave de lo que busca";
        }
     }else{
        if(this.text == "Escriba una frase o palabra clave de lo que busca"){
            this.text = "Buscar";
        }      
     }
  }
  onResize($event){
     if($event.target.innerWidth>600){
        if(this.text != "Escriba una frase o palabra clave de lo que busca"){
            this.text = "Escriba una frase o palabra clave de lo que busca";
        }
     }else{
        if(this.text == "Escriba una frase o palabra clave de lo que busca"){
            this.text = "Buscar";
        }
           
     }
  }
  buscar(termino:string){
          clearTimeout(this.typingTimer);
          this.typingTimer = setTimeout(()=>{  
          termino = termino.toLowerCase().replace(" ","-");
          if(termino != ""){
              this.router.navigate(["/buscador",termino]);
          }else{
              this.router.navigate(["/home"]);
          }
          }, 230);
    }
   
}
