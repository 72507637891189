import { Component, OnInit, Input } from '@angular/core';
import { InfoService } from '../../services/info.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css'],
  providers:[ InfoService ]
})
export class AsideComponent implements OnInit {
  vinculos:any;
  vinculosPopul:any;
  popul:number = 0;
  constructor(private _info:InfoService) {
    setTimeout(() => {
          this._info.sendVinculos().then((resp)=>{
                this.vinculos = resp;
                this.vinculosPopul = this.getVinculoPopul(this.vinculos);
                this.popul = this.getVincFast(this.vinculos);
          });
    },700);   
  }
  ngOnInit(){}
  mostrar = true;
  mostrar2 = true;
  onResize(event){
    if(event.target.innerWidth < 800){
      this.mostrar = false;
      this.mostrar2 = false; 
    }else{
      this.mostrar = true;
      this.mostrar2 = true; 
    }
  }
  getVinculoPopul(vinculos:any){
    var vinc = [];
    var i = 0;
    var  vistas = 0;
    var  nume = 0;
    if(vinculos.length >10){
      vinculos.forEach((element, index) => {
        element.numero_visitas = parseInt(element.numero_visitas)
        if(i == 0){
          vinc[index] = {data:element.id, vistas:element.numero_visitas, index:index};
        }else{
          if(vinc.length < 5){
            vinc[index] = {data:element.id ,index:index, vistas:element.numero_visitas};
          }else{
            vinc.forEach((el,ind) => {
              if(ind == 0){
                vistas = el.vistas;
                nume = ind; 
              }else{
                 if(el.vistas < vistas){
                  vistas = el.vistas;
                  nume = ind; 
                 }
              }
            });
            if(element.numero_visitas > vistas){
              vinc[nume] = {data:element.id, vistas:element.numero_visitas, index:nume};
            }
          }
        }
        i++;
      });
    }
    return vinc;
  }
  getVincFast(vinculos:any){
    var i = 0;
    vinculos.forEach((element, index) => {
       if(element.vinculo_rapido == "TRUE"){
         i++;
       }
    });
    return i;
  }
}
